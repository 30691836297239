/* Estilos para el botón Slide Left */
.ov-btn-slide-left {
    background: #ff6634;
    color: #ffffff;
    /* border: 2px solid #1b630e; */
    padding: 16px 20px;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: inline-block;
    transition: all 0.35s;
    margin-top: 20px;
  }
  
  .ov-btn-slide-left:hover {
    color: #fff;
  }
  
  .ov-btn-slide-left::after {
    content: "";
    background: #000000;
    position: absolute;
    z-index: -1;
    padding: 16px 20px;
    display: block;
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
    transition: all 0.50s;
  }
  
  .ov-btn-slide-left:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.60s;
  }
  @media screen and (max-width: 768px) {
    .ov-btn-slide-left {
        font-size: 28px; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
    }
}