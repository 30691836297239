@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
    font-family: 'Podium'; 
    src: url('./static/fonts/WarsawGothic.otf') format('opentype');
  }
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.font-podium {
    font-family: 'Podium', sans-serif;
}

.font-montserrat-200{
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
}
.font-montserrat-300{
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}
.font-montserrat-400{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}
.font-montserrat-500{
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.font-montserrat-600{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}
.font-montserrat-700{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.font-montserrat-800{
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
}
